import React, { Component } from 'react';
import { GLTable, GLTableProps, GLColumn } from '@app/components/admin/gl-table';

export interface ITableProps {
    columns?: []
    dataSource?: []
    pagination?: any
    onChange?: any
}

export const ListingTable = (props: ITableProps) => {
    return (
        <GLTable rowKey='id' bordered={false} {...props}/>
    )
}
