import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Input, Checkbox, Button, Select, InputNumber, Col, message } from 'antd-min';
import { GLForm, GLFormComponentProps, FormItemsLayout, FormHelper, ComparisonOperator, GLGlobal, alignPop } from 'gl-commonui';
import { GlobalProductModel, emptyGlobalProduct, LocalProductModel, LocalProductModelProps, LocalProductUnitPrice, LocalProductUnitPriceProps, SubProduct, SubProductProps } from '@app/service/admin/products/model';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { CommonHelper, SchoolHelper, fmtMsg, GSAdminAction, ProductType, MIN_UNIT, GRAPE_SEED_MAX_UNIT, LITTLE_SEED_MAX_UNIT, isGuid} from '@app/util';
import { FormTable } from './form-table';
import { getUnitOptionsLocalProduct, getUnitTextForLocalProduct, UnitPriceEdit, unitPriceValidator } from './unitprice';
import { SubProductSelect } from './subproduct';
import { SubmitBtns, EditDelBtns } from '@app/components';
import { RegionModel } from '@app/service/admin/regions/model';
import { cloneDeep } from 'lodash';
import { getUnitPlans } from '@app/states/school/class';
import { ProductModel } from '@app/service/school/product';
import _ from "lodash";

export interface LocalProductDetailProps {
    model?: LocalProductModel
    globalProducts?: GlobalProductModel[]
    localProducts?: LocalProductModel[]
    candidateLocalProducts?: LocalProductModel[]
    isEdit?: boolean
    regions?: RegionModel[]
    onRegionChanged?: (regionId) => void
    handleSubmit: (e) => void
    handleDelete?: (e) => void
    gotoList: () => void
}

const SchoolUnit = {
    'NotSet': { id: 0, name: 'NotSet' }
};

interface LocalProductDetailState {
    startUnit?: number
    endUnit?: number
    maxUnit?: number
    minUnit?: number
    maxUnit4Price?: number
    unitPrices?: LocalProductUnitPrice[]
    unitPrice?: LocalProductUnitPrice
    subProducts?: LocalProductModel[]
    globalProductOptions?: JSX.Element[] | null
    type?: ProductType
    unitPriceEditVisible: boolean
    subProductSelectVisible: boolean
    deletePromptVisible: boolean
    regionOptions?: JSX.Element[] | null
    model?: LocalProductModel
    regions?: RegionModel[]
    startUnitOptions?: JSX.Element[] | null
    endUnitOptions?: JSX.Element[] | null
}

@GLForm.create()
export class LocalProductDetail extends Component<LocalProductDetailProps & GLFormComponentProps, LocalProductDetailState> {

    constructor(props) {
        super(props);
        const { startUnitOptions, endUnitOptions } = setUnitOptions(ProductType.General);
        this.state = {
            startUnit: MIN_UNIT,
            endUnit: GRAPE_SEED_MAX_UNIT,
            minUnit: MIN_UNIT,
            maxUnit: GRAPE_SEED_MAX_UNIT,
            maxUnit4Price: GRAPE_SEED_MAX_UNIT,
            unitPrices: [],
            unitPrice: null,
            subProducts: [],
            globalProductOptions: null,
            type: ProductType.General,
            unitPriceEditVisible: false,
            subProductSelectVisible: false,
            deletePromptVisible: false,
            model: {},
            regions: [],
            startUnitOptions: startUnitOptions,
            endUnitOptions: endUnitOptions
        };

        this.addPrice = this.addPrice.bind(this);
        this.onAddPrice = this.onAddPrice.bind(this);
        this.onClosingPrice = this.onClosingPrice.bind(this);
        this.onRemovePrice = this.onRemovePrice.bind(this);
        this.addSubProduct = this.addSubProduct.bind(this);
        this.onAddSubProduct = this.onAddSubProduct.bind(this);
        this.onRemoveSubProduct = this.onRemoveSubProduct.bind(this);
        this.handleProductTypeChanged = this.handleProductTypeChanged.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const { isEdit, globalProducts: newGlobalProducts } = nextProps;
        const { subProducts } = prevState.model || { subProducts: undefined };
        const { unitPrices: newUnitPrices, subProducts: newSubProducts, type: localProductType } = nextProps.model || { unitPrices: undefined, subProducts: undefined, type: undefined };
        if (isEdit && nextProps.model != prevState.model) {
            let productType = ProductType.GrapeSeed;
            let maxUnit = GRAPE_SEED_MAX_UNIT;
            if (newUnitPrices) {
                productType = localProductType;
                if (productType === ProductType.LittleSeed) {
                    maxUnit = LITTLE_SEED_MAX_UNIT;
                }
            }
            const { startUnitOptions, endUnitOptions } = setUnitOptions(productType);
            return {
                ...prevState,
                unitPrices: newUnitPrices ? newUnitPrices : [],
                type: productType,
                maxUnit: maxUnit,
                maxUnit4Price: maxUnit,
                startUnitOptions: startUnitOptions,
                endUnitOptions: endUnitOptions,
                globalProductOptions: getGlobalProducts(newGlobalProducts, productType, nextProps.model, prevState.type, prevState.model),
                model: nextProps.model,
                subProducts: newSubProducts
            }
        }

        if (nextProps.regions != prevState.regions) {
            return {
                ...prevState,
                regionOptions: getRegionOptions(nextProps.regions),
                regions: nextProps.regions
            }
        }

        // if(!isEdit && nextProps.globalProducts != prevState.globalProducts){
        //     return {
        //         ...prevState,
        //         globalProductOptions: getGlobalProducts(nextProps.globalProducts,prevState.type),
        //     }
        // }

        return null;
    }

    getProductTypes(productTypes) {
        return productTypes.map((productType, index) => {
            return CommonHelper.getOption(index, productType[0], productType[1])
        });
    }

    getPriceColumns() {
        return [
            {
                title: fmtMsg({ id: GSAdminLocale.LocalProductPrice }),
                dataIndex: LocalProductUnitPriceProps.price,
                width: '20%',
                render: (text, record, index) => {
                    return CommonHelper.formatNumber(text);
                },
                align: 'right'
            },
            {
                title: fmtMsg({ id: GSAdminLocale.ProductStartUnit }),
                dataIndex: LocalProductUnitPriceProps.startUnit,
                width: '20%',
                render: (text, record, index) => {
                    return text ? parseInt(text, 10) > 0 ? getUnitTextForLocalProduct(parseInt(text, 10), false) : getUnitTextForLocalProduct(Math.abs(parseInt(text, 10)), true) : '';
                },
                align: 'right'
            },
            {
                title: fmtMsg({ id: GSAdminLocale.ProductEndUnit }),
                dataIndex: LocalProductUnitPriceProps.endUnit,
                width: '20%',
                render: (text, record, index) => {
                    return text ? parseInt(text, 10) > 0 ? getUnitTextForLocalProduct(parseInt(text, 10), false) : getUnitTextForLocalProduct(Math.abs(parseInt(text, 10)), true) : '';
                },
                align: 'right'
            },
            {
                title: "",
                width: '15%',
                render: (text, record, index) => {
                    return (
                        <div className="price-field-action">
                            <span title="edit price" className="icon-edit" onClick={() => this.onEditPrice(record)}/>
                            <span
                                title={fmtMsg({ id: GSAdminLocale.LocalProductPriceRemoveTip })}
                                className="icon-delete action"
                                onClick={() => this.onRemovePrice(record)}
                            />
                        </div>
                    )
                }
            }
        ]
    }

    getSubProductsColumns() {
        return [
            {
                title: fmtMsg({ id: GSAdminLocale.LocalProductSubProductsName }),
                dataIndex: SubProductProps.name,
                width: '50%'
            },
            {
                title: fmtMsg({ id: GSAdminLocale.ProductStartUnit }),
                dataIndex: SubProductProps.startUnit,
                width: '20%',
                render: (text, record, index) => {
                    return text ? parseInt(text, 10) > 0 ? getUnitTextForLocalProduct(parseInt(text, 10), false) : getUnitTextForLocalProduct(Math.abs(parseInt(text, 10)), true) : '';
                },
                align: 'right'
            },
            {
                title: fmtMsg({ id: GSAdminLocale.ProductEndUnit }),
                dataIndex: SubProductProps.endUnit,
                width: '20%',
                render: (text, record, index) => {
                    return text ? parseInt(text, 10) > 0 ? getUnitTextForLocalProduct(parseInt(text, 10), false) : getUnitTextForLocalProduct(Math.abs(parseInt(text, 10)), true) : '';
                },
                align: 'right'
            },
            {
                title: "",
                width: '10%',
                render: (text, record, index) => {
                    return <span
                        title={fmtMsg({ id: GSAdminLocale.LocalProductSubProductsRemoveTip })}
                        className="icon-delete action"
                        onClick={() => this.onRemoveSubProduct(record)} />
                }
            }
        ]
    }

    getStartEndUnit() {
        const { getFieldValue } = this.props.form;
        const startUnit = getFieldValue(LocalProductModelProps.startUnit);
        const endUnit = getFieldValue(LocalProductModelProps.endUnit);
        return { startUnit, endUnit }
    }

    addPrice() {
        const { startUnit, endUnit } = this.getStartEndUnit();
        this.setState({
            startUnit: startUnit ? startUnit : this.state.startUnit,
            endUnit: endUnit ? endUnit : this.state.endUnit,
            unitPrice: {
                startUnit: startUnit ? startUnit : this.state.startUnit,
                endUnit: endUnit ? endUnit : this.state.endUnit,
            },
            maxUnit4Price: startUnit && endUnit ? endUnit : null,
            unitPriceEditVisible: true
        });
    }

    onAddPrice(unitPrice: LocalProductUnitPrice) {
        let unitPrices = this.state.unitPrices || [];
        if (unitPrice && isGuid(unitPrice.id)) {
            const isAddNew = _.isEmpty(unitPrices) || !_.some(unitPrices, (item) => item.id === unitPrice.id)
            if (isAddNew) {
                unitPrices = [...unitPrices, unitPrice];
            } else {
                const index = _.findIndex(unitPrices, {id: unitPrice.id});
                unitPrices.splice(index, 1, unitPrice);
            }
            this.setState({ unitPrices });
        }
        this.onClosingPrice();
    }

    onClosingPrice() {
        //const type = this.props.form.getFieldValue(LocalProductModelProps.licenseType);
        this.setState({ unitPriceEditVisible: false, unitPrice: null });
    }

    onRemovePrice(up: LocalProductUnitPrice) {
        const unitPrices = this.state.unitPrices.filter(unitPrice => unitPrice.id != up.id);
        this.setState({ unitPrices: unitPrices });
    }

    onEditPrice(record: LocalProductUnitPrice) {
        const { startUnit, endUnit } = this.getStartEndUnit();
        this.setState({
            startUnit: startUnit ? startUnit : this.state.startUnit,
            endUnit: endUnit ? endUnit : this.state.endUnit,
            minUnit: startUnit,
            maxUnit4Price: startUnit && endUnit ? endUnit : null,
            unitPrice: {
                id: record.id,
                startUnit: record.startUnit,
                endUnit: record.endUnit,
                price: record.price
            },
            unitPriceEditVisible: true
        });
    }

    addSubProduct() {
        const { startUnit, endUnit } = this.getStartEndUnit();
        this.setState({
            subProductSelectVisible: true,
            startUnit: startUnit ? startUnit : null,
            endUnit: endUnit ? endUnit : null,
            maxUnit4Price: startUnit && endUnit ? endUnit : null,
        });
    }

    onAddSubProduct(product: SubProduct) {
        const subProduct = this.props.candidateLocalProducts.find(lp => lp.id == product.id);
        if (subProduct) {
            const newItem = { ...product, name: subProduct.name };
            this.setState({ subProducts: this.state.subProducts.concat(newItem) });
        }
        this.setState({ subProductSelectVisible: false });
    }

    onRemoveSubProduct(sp: LocalProductModel) {
        const subProducts = this.state.subProducts.filter(subProduct => subProduct.id != sp.id);
        this.setState({ subProducts: subProducts });
    }

    handleProductTypeChanged(type) {
        const { globalProducts, form } = this.props;
        const maxUnit = type == ProductType.LittleSeed ? LITTLE_SEED_MAX_UNIT : GRAPE_SEED_MAX_UNIT;
        const { startUnitOptions, endUnitOptions } = setUnitOptions(type);

        this.setState({
            maxUnit: maxUnit,
            maxUnit4Price: maxUnit,
            type: type,
            unitPrices: [],
            globalProductOptions: getGlobalProducts(globalProducts, type),
            subProducts: [],
            startUnitOptions: startUnitOptions,
            endUnitOptions: endUnitOptions
        });

        form.setFieldsValue({
            startUnit: null,
            endUnit: null,
            globalProductId: null
        });
    }

    handleDelete(e) {
        e.preventDefault();
        this.setState({ deletePromptVisible: true });
    }

    handleStartEndUnitChanged(e) {

    }

    render() {
        const { form, model = {}, candidateLocalProducts, isEdit, onRegionChanged, handleSubmit, gotoList, globalProducts } = this.props;
        const renderFormItem = FormHelper.renderFormItem;
        const productTypes = SchoolHelper.generateProductType();
        const options = {
            formItemProps: { label: null },
            decoratorOptions: { valuePropName: 'checked' }
        }
        const disableUnits = model.isPackageSubProduct || this.state.type === ProductType.General;
        const isPackageProduct = model.isPackageSubProduct;
        const globalProductId = globalProducts && globalProducts.length > 0 && model.globalProduct && this.state.globalProductOptions ? model.globalProduct.id : null;
        const { startUnit, endUnit } = this.getStartEndUnit();
        const noneUnits = this.state.type === ProductType.General || !startUnit || !endUnit;
        const products = !isPackageProduct && noneUnits ? [] :
            candidateLocalProducts.filter(product => !this.state.subProducts.find(sp => sp.id == product.id)
                && (this.state.type === product.type)
                && (!isEdit || (isEdit && product.id != model.id)));
        return (
            <React.Fragment>
                <GLForm form={form} onSubmit={onSubmit(this, handleSubmit)}>
                    <FormItemsLayout colTotal={2}>
                        {renderFormItem(form, GSAdminLocale.SchoolRegionFieldName, LocalProductModelProps.regionId,
                            <Select size='large' {...alignPop()} disabled={isEdit} onChange={onRegionChanged}>{...this.state.regionOptions}</Select>, model.regionId, !isEdit)}
                    </FormItemsLayout>
                    <FormItemsLayout colTotal={2}>
                        {renderFormItem(form, GSAdminLocale.ProductName, LocalProductModelProps.name, <Input />, model.name, true, lengthValidator(GSAdminLocale.ProductName, 200))}
                        {renderFormItem(form, GSAdminLocale.ProductLicenseType, LocalProductModelProps.type,
                            <Select size='large' {...alignPop()} onChange={this.handleProductTypeChanged} disabled={isEdit}>{this.getProductTypes(Array.from(productTypes))}</Select>, model.type || this.state.type)}
                    </FormItemsLayout>
                    <FormItemsLayout colTotal={2}>
                        {renderFormItem(form, GSAdminLocale.ProductStartUnit, LocalProductModelProps.startUnit,
                            <Select size="large" disabled={disableUnits} onChange={() => this.handleStartEndUnitChanged}>{this.state.startUnitOptions}</Select>,
                            model.startUnit, false,
                            unitPriceValidator(form, GSAdminLocale.LocalProductPriceStartUnitErrorMessage))}
                        {renderFormItem(form, GSAdminLocale.ProductEndUnit, LocalProductModelProps.endUnit,
                            <Select size="large" disabled={disableUnits} onChange={() => this.handleStartEndUnitChanged}>{this.state.endUnitOptions}</Select>,
                            model.endUnit, false,
                            unitPriceValidator(form, GSAdminLocale.LocalProductPriceEndUnitErrorMessage))}
                    </FormItemsLayout>
                    <FormTable
                        titleId={GSAdminLocale.LocalProductPrice}
                        columns={this.getPriceColumns()}
                        list={this.state.unitPrices}
                        listKey='id'
                        addItemAction={this.addPrice}
                    />
                    <FormItemsLayout colTotal={1}>
                        {renderFormItem(form, GSAdminLocale.LocalProductGlobal, LocalProductModelProps.globalProductId,
                            <Select size='large' {...alignPop()}>{this.state.globalProductOptions}</Select>, globalProductId)}
                    </FormItemsLayout>
                    {
                        model.isTextSubscription
                        ?
                            <FormItemsLayout colTotal={1}>
                                {renderFormItem({ ...form, ...options }, GSAdminLocale.LocalProductTextbookSubscription, LocalProductModelProps.isTextSubscription,
                                <Checkbox onChange={(e) => { model.isTextbookSubscription = e.target.checked; }}>
                                    <FormattedMessage id={GSAdminLocale.LocalProductTextbookSubscription} />
                                </Checkbox>, model.isTextbookSubscription)}
                            </FormItemsLayout>
                        :
                            null
                    }
                    <FormItemsLayout colTotal={1}>
                        {renderFormItem({ ...form, ...options }, GSAdminLocale.LocalProductDefaultQuantityToLicenseCount, LocalProductModelProps.defaultQuantityToLicenseCount,
                            <Checkbox onChange={(e) => { model.defaultQuantityToLicenseCount = e.target.checked; }}>
                                <FormattedMessage id={GSAdminLocale.LocalProductDefaultQuantityToLicenseCount} />
                            </Checkbox>, model.defaultQuantityToLicenseCount)}
                    </FormItemsLayout>
                    <FormItemsLayout colTotal={1}>
                        {renderFormItem({ ...form, ...options }, GSAdminLocale.LocalProductDigitalSubscription, LocalProductModelProps.isDigitalSubscription,
                            <Checkbox onChange={(e) => { model.isDigitalSubscription = e.target.checked; }}>
                                <FormattedMessage id={GSAdminLocale.LocalProductDigitalSubscription} />
                            </Checkbox>, model.isDigitalSubscription)}
                    </FormItemsLayout>
                    <FormItemsLayout colTotal={1}>
                        {isEdit && renderFormItem({ ...form, ...options }, GSAdminLocale.ProductDisabled, LocalProductModelProps.disabled,
                            <Checkbox onChange={(e) => { model.disabled = e.target.checked; }}>
                                <FormattedMessage id={GSAdminLocale.ProductDisabled} />
                            </Checkbox>, model.disabled)}
                    </FormItemsLayout>
                    <FormItemsLayout colTotal={1}>
                        {renderFormItem({ ...form, ...options }, GSAdminLocale.LocalProductAllowPurchase, LocalProductModelProps.isPurchase,
                            <Checkbox onChange={(e) => { model.isPurchase = e.target.checked; }}>
                                <FormattedMessage id={GSAdminLocale.LocalProductAllowPurchase} />
                            </Checkbox>, model.isPurchase)}
                    </FormItemsLayout>
                    <FormTable
                        titleId={GSAdminLocale.LocalProductSubProducts}
                        columns={this.getSubProductsColumns()}
                        list={this.state.subProducts}
                        listKey='id'
                        addItemAction={this.addSubProduct}
                    />
                    <SubmitBtns submitAction={GSAdminAction.EditLocalProduct} onCancel={gotoList} />
                </GLForm>
                { this.state.unitPrice &&
                    <UnitPriceEdit
                        visible={this.state.unitPriceEditVisible}
                        startUnit={this.state.startUnit}
                        endUnit={this.state.endUnit}
                        minUnit={this.state.minUnit}
                        maxUnit={this.state.maxUnit4Price}
                        unitPrices={this.state.unitPrices}
                        unitPrice={this.state.unitPrice}
                        onSave={this.onAddPrice}
                        onCancel={() => this.onClosingPrice()}
                    />
                }
                <SubProductSelect
                    visible={this.state.subProductSelectVisible}
                    products={products}
                    minUnit={this.state.startUnit}
                    maxUnit={this.state.maxUnit4Price}
                    onSave={this.onAddSubProduct}
                    onCancel={() => this.setState({ subProductSelectVisible: false })}
                />
            </React.Fragment>
        );
    }
}

function lengthValidator(localeId, rigthVal) {
    return [FormHelper.ruleForCompareLength(localeId, ComparisonOperator.LessOrEqualsThan, rigthVal)];
}

function isUnitValid(form, localProduct: LocalProductModel) {
    let [result, field, prop, error, prop1, error1] = [true, {}, null, null, null, null];
    if (localProduct.type !== ProductType.General && !localProduct.startUnit && localProduct.endUnit) {
        prop = LocalProductModelProps.startUnit;
        error = [new Error(FormHelper.ruleForRequire(GSAdminLocale.ProductStartUnit).message)];
        result = false;
    }
    else if (localProduct.type !== ProductType.General && localProduct.startUnit && !localProduct.endUnit) {
        prop = LocalProductModelProps.endUnit;
        error = [new Error(FormHelper.ruleForRequire(GSAdminLocale.ProductEndUnit).message)];
        result = false;
    }
    else if (localProduct.type !== ProductType.General && !localProduct.startUnit && !localProduct.endUnit) {
        prop = LocalProductModelProps.startUnit;
        prop1 = LocalProductModelProps.endUnit;
        error = [new Error(FormHelper.ruleForRequire(GSAdminLocale.ProductStartUnit).message)];
        error1 = [new Error(FormHelper.ruleForRequire(GSAdminLocale.ProductEndUnit).message)];
        result = false;
    }
    else {
        result = true;
    }
    if (!result) {
        field[prop] = { errors: error };
        field[prop1] = { errors: error1 };
        form.setFields(field);
    }
    return result;
}

function getUnitsSum(unitPrices: LocalProductUnitPrice[]) {
    return unitPrices.reduce((accumulator, current) => accumulator + Math.abs(current.endUnit) - Math.abs(current.startUnit) + 1, 0);
}

function isUnitPriceValid(localProduct: LocalProductModel) {
    if (localProduct.unitPrices && localProduct.unitPrices.length > 0) {

        const allPositiveStartUnit = localProduct.unitPrices.every(unitPrice => unitPrice.startUnit > 0);
        const allNegativeStartUnit = localProduct.unitPrices.every(unitPrice => unitPrice.startUnit < 0);

        const allPositiveEndUnit = localProduct.unitPrices.every(unitPrice => unitPrice.endUnit > 0);
        const allNegativeEndUnit = localProduct.unitPrices.every(unitPrice => unitPrice.endUnit < 0);

        const startUnit = allPositiveStartUnit ? Math.min(...localProduct.unitPrices.map(unitPrice => unitPrice.startUnit)) : allNegativeStartUnit ? Math.max(...localProduct.unitPrices.map(unitPrice => unitPrice.startUnit)) :
            Math.max(...localProduct.unitPrices.filter(unitPrice => unitPrice.startUnit < 0).map(unitPrice => unitPrice.startUnit));

        const endUnit = allPositiveEndUnit ? Math.max(...localProduct.unitPrices.map(unitPrice => unitPrice.endUnit)) : allNegativeEndUnit ? Math.min(...localProduct.unitPrices.map(unitPrice => unitPrice.endUnit)) :
            Math.max(...localProduct.unitPrices.filter(unitPrice => unitPrice.endUnit > 0).map(unitPrice => unitPrice.endUnit));

        const units = localProduct.unitPrices.filter(up => up.startUnit != null && up.endUnit != null);
        const noneUnits = localProduct.unitPrices.filter(up => !up.startUnit || !up.endUnit);
        if (units.length > 0 && noneUnits.length > 0) return { valid: false, messageId: GSAdminLocale.LocalProductPricesConfusion };
        if (noneUnits.length > 1) return { valid: false, messageId: GSAdminLocale.LocalProductPricesOverflow };
        if (localProduct.startUnit && localProduct.endUnit) {
            let valid = startUnit == localProduct.startUnit && endUnit == localProduct.endUnit;

            if ((allPositiveStartUnit && allPositiveEndUnit) || (allNegativeStartUnit && allNegativeEndUnit)) {
                let units = getUnitsSum(localProduct.unitPrices);
                valid = valid && (Math.abs(endUnit) - Math.abs(startUnit) + 1) == units;
            }
            else {
                let negativeElements = [];
                let positiveElements = [];

                localProduct.unitPrices.forEach(x => {
                    if (x.startUnit > 0 && x.endUnit > 0) {
                        positiveElements.push(x)
                    }
                    else if (x.startUnit < 0 && x.endUnit < 0) {
                        negativeElements.push(x)
                    }
                    else if (x.startUnit < 0 && x.endUnit > 0) {
                        let positiveElement = cloneDeep(x);
                        positiveElement.startUnit = MIN_UNIT;

                        let negativeElement = cloneDeep(x);
                        negativeElement.endUnit = -1 * LITTLE_SEED_MAX_UNIT;

                        positiveElements.push(positiveElement);
                        negativeElements.push(negativeElement);
                    }
                });

                let negativeRangeUnit = Math.abs(-1 * LITTLE_SEED_MAX_UNIT) - Math.abs(startUnit) + 1;
                let positiveRangeUnit = Math.abs(endUnit) - Math.abs(1) + 1;

                valid = valid && (positiveRangeUnit + negativeRangeUnit) === (getUnitsSum(positiveElements) + getUnitsSum(negativeElements));
            }

            if (!valid) return { valid: valid, messageId: GSAdminLocale.LocalProductPricesUnitConfusion }
            if (localProduct.subProducts.length > 0) {
                const allPositiveSubStartUnit = localProduct.subProducts.every(subProduct => subProduct.startUnit > 0);
                const allNegativeSubStartUnit = localProduct.subProducts.every(subProduct => subProduct.startUnit < 0);

                const allPositiveSubEndUnit = localProduct.subProducts.every(subProduct => subProduct.endUnit > 0);
                const allNegativeSubEndUnit = localProduct.subProducts.every(subProduct => subProduct.endUnit < 0);

                const subStartUnit = allPositiveSubStartUnit ? Math.min(...localProduct.subProducts.map(subProduct => subProduct.startUnit)) : allNegativeSubStartUnit ? Math.max(...localProduct.subProducts.map(subProduct => subProduct.startUnit)) :
                    Math.max(...localProduct.subProducts.filter(subProduct => subProduct.startUnit < 0).map(subProduct => subProduct.startUnit));

                const subEndUnit = allPositiveSubEndUnit ? Math.max(...localProduct.subProducts.map(subProduct => subProduct.endUnit)) : allNegativeSubEndUnit ? Math.min(...localProduct.subProducts.map(subProduct => subProduct.endUnit)) :
                    Math.max(...localProduct.subProducts.filter(subProduct => subProduct.endUnit > 0).map(subProduct => subProduct.endUnit));


                if ((startUnit > 0 && endUnit > 0) || (startUnit < 0 && endUnit < 0)) {
                    valid = valid && Math.abs(subStartUnit) >= Math.abs(startUnit) && Math.abs(subEndUnit) <= Math.abs(endUnit);
                }
                else if (startUnit < 0 && endUnit > 0) {

                    if (subStartUnit < 0 && subEndUnit < 0) {
                        valid = valid && Math.abs(subStartUnit) >= Math.abs(startUnit) && Math.abs(subEndUnit) <= Math.abs(-1 * LITTLE_SEED_MAX_UNIT);
                    }
                    else if ((subStartUnit > 0 && subEndUnit > 0) || (subStartUnit < 0 && subEndUnit > 0)) {
                        valid = valid && Math.abs(subStartUnit) >= Math.abs(MIN_UNIT) && Math.abs(subEndUnit) <= Math.abs(endUnit);
                    }
                }

                return { valid: valid, messageId: GSAdminLocale.LocalProductSubProductUnitConfusion };
            }
            else {
                return { valid: true };
            }
        }
        else if (localProduct.isPackageSubProduct && localProduct.unitPrices.length > 1) {
            return { valid: false, messageId: GSAdminLocale.LocalProductPricesCountError };
        }
        else if (localProduct.globalProductId) {
            return { valid: false, messageId: GSAdminLocale.LocalProductGlobalErrorMessage };
        }
        else {
            return { valid: true };
        }
    }
    else {
        return { valid: false, messageId: GSAdminLocale.LocalProductPricesRequired };
    }
}

function setUnitOptions(productType: ProductType) {

    let startUnitOptions = [];
    let endUnitOptions = [];

    if (productType === ProductType.LittleSeed) {
        startUnitOptions.push(getUnitOptionsLocalProduct(MIN_UNIT, LITTLE_SEED_MAX_UNIT, true, "localdetailstartunit"));
        endUnitOptions.push(getUnitOptionsLocalProduct(MIN_UNIT, LITTLE_SEED_MAX_UNIT, true, "localdetailendunit"));
    }
    else if (productType == ProductType.GrapeSeed) {
        startUnitOptions.push(getUnitOptionsLocalProduct(MIN_UNIT, GRAPE_SEED_MAX_UNIT, false, "localdetailstartunit"));
        endUnitOptions.push(getUnitOptionsLocalProduct(MIN_UNIT, GRAPE_SEED_MAX_UNIT, false, "localdetailendunit"));
    }
    // else if (productType === ProductType.General) {
    //     startUnitOptions.push(getUnitOptionsLocalProduct(MIN_UNIT, LITTLE_SEED_MAX_UNIT, true, "localdetailstartunit"));
    //     startUnitOptions.push(getUnitOptionsLocalProduct(MIN_UNIT, GRAPE_SEED_MAX_UNIT, false, "localdetailstartunit"));
    //     endUnitOptions.push(getUnitOptionsLocalProduct(MIN_UNIT, LITTLE_SEED_MAX_UNIT, true, "localdetailendunit"));
    //     endUnitOptions.push(getUnitOptionsLocalProduct(MIN_UNIT, GRAPE_SEED_MAX_UNIT, false, "localdetailendunit"));

    // }

    return { startUnitOptions, endUnitOptions };
}


function isLocalProductValid(form, localProduct: LocalProductModel) {
    const formatMessage = GLGlobal.intl.formatMessage;
    let messagePrompted = false;
    const unitResult = isUnitValid(form, localProduct);
    if (!unitResult) messagePrompted = localProduct.unitPrices.length > 0;
    const unitPriceResult = isUnitPriceValid(localProduct);
    if (!messagePrompted && !unitPriceResult.valid) {
        message.error(formatMessage({ id: unitPriceResult.messageId }));
        messagePrompted = true;
    }
    return unitResult && unitPriceResult.valid;
}

function onSubmit(component, handleSubmit) {
    return e => {
        e.preventDefault();
        const { props: { form, model }, state: { unitPrices, subProducts } } = component;
        form.validateFields((err, values) => {
            const product = { ...model, ...values, unitPrices: unitPrices, subProducts };
            if (!err && isLocalProductValid(form, product)) {
                handleSubmit({ ...product });
            }
        });
    }
}

function getRegionOptions(regions: RegionModel[]) {
    return regions && regions.map((region, index) => {
        return CommonHelper.getOption(index, region.id, region.englishName) // region.name)
    });
}

function getGlobalProducts(globalProducts, type = null, localProductModel = null, preType = null, preLocalProductModel = null) {
    const mergedType = type == null ? preType : type;
    if (mergedType === ProductType.General) return null;
    const localProduct = localProductModel ? localProductModel : preLocalProductModel;

    // let candidates = [];

    // if (mergedType === ProductType.LittleSeed) {
    //     candidates = globalProducts.filter(gp => !gp.disabled && gp.isLittleSeed == true
    //         || (localProduct && localProduct.globalProduct && gp.id == localProduct.globalProduct.id));
    // }
    // else if (mergedType === ProductType.GrapeSeed) {
    //     candidates = globalProducts.filter(gp => !gp.disabled && gp.isLittleSeed == false
    //         || (localProduct && localProduct.globalProduct && gp.id == localProduct.globalProduct.id));
    // }
    // else {
    //     candidates = globalProducts.filter(gp => !gp.disabled
    //         || (localProduct && localProduct.globalProduct && gp.id == localProduct.globalProduct.id));
    // }

    let candidates = globalProducts.filter(gp => !gp.disabled
        || (localProduct && localProduct.globalProduct && gp.id == localProduct.globalProduct.id));

    return candidates && [emptyGlobalProduct].concat(candidates).map((globalProduct, index) => {
        return CommonHelper.getOption(index, globalProduct.id, globalProduct.name)
    });
}
