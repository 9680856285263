import React, { Component } from 'react';
import { GLGlobal } from 'gl-commonui';
import { ListingTable } from './table';
import { GSAdminLocale } from '@app/locales/localeid';
import { Icon } from 'antd-min';

export interface IFormTableProps {
    titleId: string
    list: any
    listKey: string
    columns: any
    addItemAction: () => void
}
export interface IListingPageStates {
    
}

export class FormTable extends Component<IFormTableProps, IListingPageStates> {
    tableProps: any = {
        pagination: false,
    }

    constructor(props) {
        super(props);
        this.state = {
            
        }        
    }

    render() {
        const  {addItemAction, list: dataSource, listKey, columns, titleId } = this.props;
        const title = GLGlobal.intl.formatMessage({id: titleId}) || titleId;
        const updatedTableProps = { dataSource, columns, pagination: this.tableProps.pagination, rowKey: listKey, rowClassName: this.tableProps.rowClassName };
        const mergedTableProps = {...this.tableProps, ...updatedTableProps};
        return (
            <div className="form-table">
                <div className="form-table-title">
                    <h3>{title}</h3>
                    <div className="form-table-title-addlink">
                        <a onClick={(e)=> {e.preventDefault(); addItemAction();}} >
                            <Icon type="plus-circle" />
                            <span>{GLGlobal.intl.formatMessage({ id: GSAdminLocale.ProductAdd })}</span>
                        </a>
                    </div>
                </div>
                <ListingTable {...mergedTableProps} />
            </div>            
        )
    }
}