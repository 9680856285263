import * as React from 'react'
import { Component } from 'react';
import { Form, Select, Modal } from "antd-min";
import { GLForm, GLFormProps, FormHelper, FormItemsLayout, GLGlobal } from 'gl-commonui';
import { GSAdminLocale } from '@app/locales/localeid';
import { LocalProductModel, LocalProductUnitPriceProps, SubProduct } from '@app/service/admin/products/model';
import { getUnitOptionsLocalProduct, unitPriceValidator } from './unitprice';
import { SubmitBtns } from '@app/components';
import { GRAPE_SEED_MAX_UNIT, LITTLE_SEED_MAX_UNIT, MIN_UNIT } from '@app/util';

interface SubProductSelectProps extends GLFormProps {
    visible: boolean
    products: LocalProductModel[]
    minUnit?: number
    maxUnit?: number
    onSave?: (product: SubProduct) => void
    onCancel?: () => void
}

interface SubProductSelectStates {
    hasUnit?: boolean
    startUnit?: number
    endUnit?: number
    unitOptions?: JSX.Element[] | null
}

@GLForm.create()
export class SubProductSelect extends React.Component<SubProductSelectProps, SubProductSelectStates> {
    constructor(props) {
        super(props);
        this.state = {
            hasUnit: true,
            startUnit: null,
            endUnit: null
        };
        this.handleSubProductChange = this.handleSubProductChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    getOption(key, value, text) {
        return <Select.Option key={key} value={value}>{text}</Select.Option>
    }

    getProductOptions(products) {
        return products && products.map((product, index) => {
            return this.getOption(index, product.id, product.name)
        });
    }

    // getUnitOptions(minUnit:number, maxUnit:number) {
    //     return [...Array(maxUnit).keys()].filter((unit)=>unit>=minUnit-1).map((unitKey, index)=> {
    //         return this.getOption(index + 1, unitKey + 1, unitKey + 1)
    //     });
    // }

    getUnitOptions(minUnit: number, maxUnit: number) {

        let unitOptions = [];

        if (minUnit && maxUnit && minUnit < 0 && maxUnit < 0 && Math.abs(minUnit) > Math.abs(maxUnit)) {
            return unitOptions;
        }
        else if (minUnit && maxUnit && ((minUnit > 0 && maxUnit > 0) ||
            (minUnit > 0 && maxUnit < 0) || (minUnit < 0 && maxUnit > 0))
            && minUnit > maxUnit) {
            return unitOptions;
        }

        if (minUnit < 0 && maxUnit < 0) {
            unitOptions.push(getUnitOptionsLocalProduct(Math.abs(minUnit), Math.abs(maxUnit), true, "subproductunitoptions"));
        }
        else if (minUnit > 0 && maxUnit > 0) {
            unitOptions.push(getUnitOptionsLocalProduct(minUnit, maxUnit, false, "subproductunitoptions"));
        }
        else if (minUnit < 0 && maxUnit > 0) {
            unitOptions.push(getUnitOptionsLocalProduct(Math.abs(minUnit), LITTLE_SEED_MAX_UNIT, true, "subproductunitoptions"));
            unitOptions.push(getUnitOptionsLocalProduct(MIN_UNIT, maxUnit, false, "subproductunitoptions"));;
        }

        return unitOptions;
    }



    getStartEndUnit() {
        const { getFieldValue } = this.props.form;
        const startUnit = getFieldValue(LocalProductUnitPriceProps.startUnit);
        const endUnit = getFieldValue(LocalProductUnitPriceProps.endUnit);
        return { startUnit, endUnit };
    }

    // isLittleSeedProduct() {
    //     return this.props.products.filter(product => product.startUnit < 0).length > 0;
    // }

    checkUnits(unit1: number, unit2: number) {
        if (unit1 > 0 && unit2 > 0) {
            return true;
        }
        else if (unit1 < 0 && unit2 < 0) {
            return false;
        }
        else {
            return null;
        }
    }

    handleSubProductChange(productId) {
        const product = this.props.products.find(p => p.id == productId);
        if (product) {
            const minUnit = product.startUnit && product.startUnit;
            const maxUnit = product.endUnit && product.endUnit;
            const productMinUnit = this.props.minUnit ? this.props.minUnit : -1 * MIN_UNIT;
            const minIsNegativePositive = this.checkUnits(minUnit, productMinUnit);
            const mergedMinUnit = minIsNegativePositive == false ? -1 * Math.max(Math.abs(minUnit), Math.abs(productMinUnit)) : Math.max(minUnit, productMinUnit);
            const productMaxUnit = this.props.maxUnit ? this.props.maxUnit : GRAPE_SEED_MAX_UNIT;
            const maxIsNegativePositive = this.checkUnits(maxUnit, productMaxUnit)
            const mergedMaxUnit = maxIsNegativePositive == false ? -1 * Math.min(Math.abs(maxUnit), Math.abs(productMaxUnit)) : Math.min(maxUnit, productMaxUnit);
            const options = this.getUnitOptions(mergedMinUnit, mergedMaxUnit);
            const { startUnit, endUnit } = this.getStartEndUnit();
            const { setFieldsValue } = this.props.form;
            setFieldsValue({ 'startUnit': options.length > 0 ? mergedMinUnit : null });
            setFieldsValue({ 'endUnit': options.length > 0 ? mergedMaxUnit : null });
            this.setState({
                hasUnit: product.startUnit != null,
                startUnit: options.length == 0 ? null : mergedMinUnit,
                endUnit: options.length == 0 ? null : mergedMaxUnit,
                unitOptions: minUnit && mergedMaxUnit ? options : null
            });
        }
    }

    onValidateProduct() {
        const form = this.props.form;
        //  const multiplier = this.isLittleSeedProduct() ? -1 : 1;
        let product = null;
        form.validateFields((err, values) => {
            if (!err) {
                product = values;
                // product.startUnit *= multiplier;
                // product.endUnit *= multiplier;
            }
        });
        return product;
    }

    onSave(e) {
        e.preventDefault();
        const product = this.onValidateProduct();
        if (!product) return;
        const { onSave } = this.props;
        if (onSave) {
            this.setState({
                hasUnit: true,
                startUnit: null,
                endUnit: null,
                unitOptions: null
            }, () => onSave(product));
        }
    }

    onCancel() {
        const { onCancel } = this.props;
        if (onCancel) {
            this.setState({
                hasUnit: true,
                startUnit: null,
                endUnit: null,
                unitOptions: null
            }, () => onCancel());
        };
    }

    render() {
        const { products } = this.props;
        const form = this.props.form;
        const { renderFormItem } = FormHelper;
        return (
            <Modal
                title={GLGlobal.intl.formatMessage({ id: GSAdminLocale.ProductSubAdd })}
                closable={false}
                footer={null}
                className={'localproduct-modal'}
                visible={this.props.visible}
                destroyOnClose={true}
            >
                <GLForm form={form} onSubmit={this.onSave}>
                    <FormItemsLayout colTotal={1}>
                        {renderFormItem(form, GSAdminLocale.LocalProductSubProductsName, 'id',
                            <Select size="large" onChange={this.handleSubProductChange}>{this.getProductOptions(products)}</Select>, null, true)}
                        {this.state.hasUnit && renderFormItem(form, GSAdminLocale.ProductStartUnit, LocalProductUnitPriceProps.startUnit,
                            <Select size="large">{this.state.unitOptions}</Select>, this.state.startUnit, this.state.hasUnit,
                            unitPriceValidator(form, GSAdminLocale.LocalProductPriceStartUnitErrorMessage))}
                        {this.state.hasUnit && renderFormItem(form, GSAdminLocale.ProductEndUnit, LocalProductUnitPriceProps.endUnit,
                            <Select size="large">{this.state.unitOptions}</Select>, this.state.endUnit, this.state.hasUnit,
                            unitPriceValidator(form, GSAdminLocale.LocalProductPriceStartUnitErrorMessage))}
                    </FormItemsLayout>
                    <Form.Item>
                        <SubmitBtns submitTitle={GSAdminLocale.ProductAdd} onCancel={this.onCancel} />
                    </Form.Item>
                </GLForm>
            </Modal>
        )
    }
}